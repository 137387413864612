import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar"
import HeroSection from "./components/HeroSection/HeroSection";
import Footer from "./components/Footer/Footer";
import './App.css'; // If using CSS
import ServicesSection from './components/ServicesSection/ServicesSection'
import InstagramEmbed from "./components/InstagramEmbed/InstagramEmbed";
import GallerySection from "./components/GallerySection/GallerySection";

function App() {
  return (
    <Router>
      <Navbar />
      <HeroSection />
      <ServicesSection />
      <GallerySection />
      <Footer />
    </Router>
  );
}

export default App;
